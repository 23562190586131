export const environment = {
	production: true,
	token: '==54Krws597!Tgk&eD',
	api_url: 'https://api-web.jockeyplaza.com.pe',
	timeRenderCards: 3000,
	timeAnimationSlider: 5000,
	recaptcha: '6LeA-8gnAAAAAHqLCzoow6Us69En23UGQi7ydfLK',
	recaptchav2: '6LeG_cgnAAAAAFlOeX21YW2BJsuumtYYTxA37L84',
	keyLocalStorage: 'jockeyLocal2023',
	endpointAforo: '',
	keyGoogleMaps: 'AIzaSyAFOFTWqQtmhSnE2sBeJ0Om2xU4er0UQEk',
	venueMappedin: 'jockey-plaza-peru',
	clientIdMappedin: '63d95956c812ca001d1409ab',
	clientSecretMappedin: '5p3CTxPIzxpos5bie9N2kwyhFpm0aAzMA0NzKNPLciRJzduS',
	clientIdFacebook: '291769163270202',
	clientSecretFacebook: '33b6880105ac9212494d0ee1ea8dc384',
	versionFacebook: 'v17.0',
	urlMedia: 'https://jockeyplaza.com.pe/media'
};
